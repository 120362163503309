.App {
  text-align: left;
  background-color: #282c34;
  font-family: Arial, Arial, Helvetica, sans-serif;
  color: white;
}

.App a {
  color: #61dafb;
}

.App header {
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  padding: 0 20px;
}

.App header h2 {
  font-size: 0.6em;
  margin-block-start: 0;
}

.App main nav.elements {
  position: absolute;
  left: -800%
}

.PeriodicTable {
  margin: 15px;
}

.PeriodicTable .spacer-row {
  height: 30px;
}

.PeriodicTable .PeriodicTableRow {
  display: flex;
  justify-content: center;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell,
.PeriodicTable .PeriodicTableRow .PeriodicTableCellSpacer {
  height: 80px;
  width: 50px;
  display: inline-flex;
  border: solid 1px transparent;
  padding: 10px;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell {
  border: solid 1px #282c34;
  color: #282c34;
  display: flex;
  flex-flow: column;
  background-color: white;
  transition: all .2s ease-in-out;
}

.PeriodicTable .PeriodicTableRow .summary {
  position: absolute !important;
  left: -500% !important;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-diatomic-nonmetal,
.PeriodicTable .PeriodicTableRow .PeriodicTableCell-polyatomic-nonmetal
 {
  background-color: yellow;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-noble-gas {
  background-color: pink;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-alkali-metal {
  background-color: violet;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-alkaline-earth-metal {
  background-color: skyblue;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-transition-metal {
  background-color: lightgreen;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-metalloid {
  background-color: orange;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-post-transition-metal {
  background-color: greenyellow;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-lanthanide {
  background-color: aquamarine;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell-actinide {
  background-color: lightblue;
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell:hover {
  transform: scale(1.3);
}

.PeriodicTable .PeriodicTableRow .PeriodicTableCell .number {
  text-align: right;
}
.PeriodicTable .PeriodicTableRow .PeriodicTableCell .symbol {
  font-size: 2em;
  text-align: center;
  font-weight: bold;
}
.PeriodicTable .PeriodicTableRow .PeriodicTableCell .element-name,
.PeriodicTable .PeriodicTableRow .PeriodicTableCell .atomic-mass {
  font-size: 0.5em;
  text-align: center;
}

.App main .explanation {
  text-align: center;
  padding: 10px 80px;
}

.App main .explanation p {
  text-align: left;
}

.App footer {
  text-align: right;
  padding: 10px 0;
}

.App footer ul {
  list-style: none;
}

.App footer ul li {
  display: inline;
  padding: 0 15px;
  font-weight: bold;
  font-size: 0.9em;
}